<template>
    <svg viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path d="M5,15.2c0,1.4-1.1,2.5-2.5,2.5S0,16.6,0,15.2c0-1.4,1.1-2.5,2.5-2.5H5V15.2z" fill="#E01E5A"/>
          <path d="M6.3,15.2c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v6.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5 C6.3,21.5,6.3,15.2,6.3,15.2z"
                fill="#E01E5A"/>
      </g>
        <g>
        <path d="M8.8,5C7.4,5,6.3,3.9,6.3,2.5S7.4,0,8.8,0s2.5,1.1,2.5,2.5V5H8.8z" fill="#36C5F0"/>
            <path d="M8.8,6.3c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5H2.5C1.1,11.4,0,10.2,0,8.8s1.1-2.5,2.5-2.5 C2.5,6.3,8.8,6.3,8.8,6.3z"
                  fill="#36C5F0"/>
      </g>
        <g>
        <path d="M19,8.8c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5H19V8.8z" fill="#2EB67D"/>
            <path d="M17.7,8.8c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5 V8.8z"
                  fill="#2EB67D"/>
      </g>
        <g>
        <path d="M15.2,19c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V19H15.2z" fill="#ECB22E"/>
            <path d="M15.2,17.7c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h6.3c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5 H15.2z"
                  fill="#ECB22E"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
