<template>
    <svg aria-hidden="true" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="none" points="9.5,15.5 14.5,15.5 15.7,8.5 8.2,8.5"/>
        <path
            d="M0.8,1.2C0.4,1.2,0,1.5,0,2c0,0,0,0.1,0,0.1l3.3,19.8c0.1,0.5,0.5,0.9,1,0.9H20c0.4,0,0.7-0.3,0.8-0.6l3.3-20 c0.1-0.4-0.2-0.8-0.6-0.9c0,0-0.1,0-0.1,0L0.8,1.2z M14.5,15.5h-5L8.2,8.5h7.6L14.5,15.5z"
            fill="#2684FF"/>

        <linearGradient id="grad1" gradientTransform="matrix(1 0 0 -1 0 26.73)" gradientUnits="userSpaceOnUse" x1="24.6235" x2="12.6969" y1="16.2853"
                        y2="6.977">
            <stop offset="0.18" style="stop-color:#0052CC"/>
            <stop offset="1" style="stop-color:#2684FF"/>
        </linearGradient>
        <path d="M23,8.5h-7.2l-1.2,7.1h-5l-5.9,7c0.2,0.2,0.4,0.3,0.7,0.3H20c0.4,0,0.7-0.3,0.8-0.6L23,8.5z"
              fill="url(#grad1)"/>
    </svg>
</template>

<script>
export default {};
</script>
